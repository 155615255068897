import { ID } from '../common';
import { ExternalSystem } from '../external-system';

export interface Warehouse {
  warehouseId: ID;
  warehouseName: string;
}

export interface SourceWarehouse extends Warehouse {
  externalSystemId: string;
  externalSystemType: ExternalSystem;
}
