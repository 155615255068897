import { Department, Project } from '../unimicro-platform-data';

export interface SalesChannelSettings {
  invoicingStartDateUtc: number | null;
  department: Department | null;
  project: Project | null;
}

export interface ShopifySettings extends SalesChannelSettings {
  shippingSku: string;
  defaultProductSku: string;
}
