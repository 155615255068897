import { ID } from './common';
import { Company, CompanyConnection, CompanyUser } from './company';

export interface UserBase {
  id: ID;
  fullName: string;
  email: string;
}

export interface UserCompany extends Company {
  connections: CompanyConnection[];
  users: CompanyUser[];
}

export interface User extends UserBase {
  companies: UserCompany[];
}
