import { ExternalSystem, ExternalSystemTypeFeatureBillingable } from './external-system';
import { Locale } from './l10n';

export type Agreement = {
  id: number;
  name: string;
  feature: ExternalSystemTypeFeatureBillingable;
  externalSystemTypes?: ExternalSystem[],
  monthlyPriceExclVat: number;
  currencyCode: string;
  freeDaysIncluded: number;
  priceSteps: AgreementPriceStep[];
  priceStepsAreVolumeBased: boolean;
  contents: AgreementContent[];
};

export type AgreementPriceStep = {
  priceExclVat: number;
  stepLimit: number;
};

export type AgreementContent = {
  id: number;
  agreementId: number;
  referenceId: string;
  localeId: Locale;
  content: unknown;
};

export type CompanyAgreementConnection = {
  systemId: string;
  systemType: ExternalSystem;
}

export type CompanyAgreementRequest = {
  agreementId: number;
  connections: CompanyAgreementConnection[];
}
