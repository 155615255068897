export interface TotalTransactionCountReport {
  invoicesCount: number;
  settlementsCount: number;
}

export interface TotalRequiredAttentionTransactionReport {
  invoicesCount: number;
}

export interface SuccessfulTransactionCountPerDayReport {
  timestamp: number;
  invoicesCount: number;
  settlementsCount: number;
}

export interface SuccessfulTransactionCountPerDayReportRequestOptions {
  dateRangeStart: number;
  dateRangeEnd: number;
}
