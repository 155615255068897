import { SignalRMessageCode, SignalRNotificationType } from './signalr';

export interface Notification {
  id: string;
  date: string;
  message: string;
  messageCode: SignalRMessageCode;
  read: boolean;
  type: SignalRNotificationType;
}
